<template>
    <div>
        <h1>Ответы в pdf формате</h1>

        <div class="d-flex flex-row align-center">
            <span>Фильтр</span>
            
            <v-select
                v-model="filter.year"
                :items="years"
                label="Год"
                :disabled="loading"
                class="ml-5"
                @change="fetchData"
            ></v-select>
            <v-select
                v-model="filter.subject"
                :items="subjects"
                label="Предмет"
                :disabled="loading"
                class="ml-5"
                @change="fetchData"
            ></v-select>
        </div>

        <v-alert
            v-if="!_.isNil(error)"
            dense
            type="error"
        >
            {{ error }}
        </v-alert>

        <div class="d-flex my-4" style="height: 15px">
            <v-progress-linear
                v-if="loading"
                color="cyan darken-2"
                indeterminate
            />
        </div>

        <div v-if="filter.year && filter.subject">
            <div
                v-for="grade in grades"
                :key="grade"
                class="d-flex align-center mb-4"
            >   
                <template v-if="models[grade] !== undefined">
                    <div class="mr-4">Класс {{ grade }}</div>
                    <media-library
                        v-model="models[grade]"
                        :path="`${filter.year}/${filter.subject}/${grade}`"
                        label="Файл"
                        type="pdf"
                        @input="onFileChanged($event, grade)"
                    />
                </template>
            </div>
            
            <div class="d-flex">
                <v-spacer />
                <v-btn
                    v-if="models.length"
                    :dark="!!updatedGrades.length"
                    small
                    :color="$const.color.primary"
                    :disabled="!updatedGrades.length"
                    :loading="loading" 
                    @click.prevent.stop="save"
                >
                    Сохранить
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import MediaLibrary from '@/components/inputs/media-library'
import stringHelper from '@/plugins/string'

export default {
    name: 'JobAnswersPdf',
    components: { MediaLibrary },
    data () {
        return {
            filter: {
                year: null,
                subject: null
            },
            loading: false,
            error: null,
            models: [],
            updatedGrades: []
        }
    },
    computed: {
        grades () {
            return (new Array(11)).fill(null).map((item, index) => index + 1)
        },
        subjects () {
            return this.$store.state.job.subjects
                .map(s => ({ text: s.text, value: stringHelper.transliterate(s.value.toLowerCase()) }))
        },
        years () {
            const years = []
            for (let y = 2023; y <= (new Date()).getFullYear(); y++)
                years.push(y)
            return years
        }
    },
    created () {
        this.filter.year = this.years[this.years.length - 1]
    },
    methods: {
        onFileChanged (payload, grade) {
            !this.updatedGrades.includes(grade) && this.updatedGrades.push(grade)
        },
        async save () {
            this.loading = true
            try {
                const promises = this.updatedGrades
                                    .map(grade => this.upsertData({
                                                                year: this.filter.year,
                                                                subject: this.subjects.find(subj => subj.value === this.filter.subject)?.text,
                                                                grade,
                                                                pdf: this.models[grade]
                                                            })
                                                        )
                await Promise.all(promises)
                this.updatedGrades = []
            } catch (error) {
                console.error(error)
                this.error = error
            } finally {
                this.loading = false
            }
        },
        async upsertData ({pdf, year, subject, grade} = {}) {
            if (!year || !subject || !grade )
                throw new Error('Can\'t upsert data without required parameters')

            if (!pdf) { pdf = null }

            const { success, error} = await this.$store.dispatch(
                                                        'pdf_answer_of_job/upsert',
                                                        { year, subject, grade, pdf }
                                                    )
                if (!success)
                    this.error = error
        },
        async fetchData () {
            if (!this.filter.year || !this.filter.subject) { return false }
            this.loading = true
            this.error = null
            this.models = []
            this.updatedGrades = []
            try {
                const { success, data, error} = await this.$store.dispatch(
                                                        'pdf_answer_of_job/list',
                                                        {
                                                            filter: {
                                                                year: this.filter.year,
                                                                subject: this.subjects.find(subj => subj.value === this.filter.subject)?.text
                                                            }
                                                        }
                                                    )
                if (!success)
                    this.error = error
            
                this.grades.forEach(grade => {
                    this.models[grade] = data?.items.find(item => item.grade === grade)?.pdf || null
                })
            } catch (error) {
                console.error(error)
                this.error = error
            } finally {
                this.loading = false
            }
        }
    }
}
</script>